@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff9e7 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiList-root {
  background-color: #fcedbf !important;
}
.MuiDialogTitle-root {
  background-color: #cabd97 !important;
  color: 'white' !important;
}
.MuiButton-containedPrimary {
  background-color: #cabd97 !important;
}

.MuiPaper-root {
  background-color: transparent !important;
}

.MuiBox-root {
  overflow: hidden !important;
}

.fact-card {
  background-color: #faf1e0 !important;
  border-radius: 5px !important;
}

.referral-card {
  background-color: #faf1e0 !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.fact {
  background-color: rgba(255, 255, 255) !important;
  border-color: #c83258;
  border-radius: 5px !important;
}

.custom-button {
  background-color: #f4b42c !important;
  font-size: 20px !important;
  color: black !important;
  /* color: white !important; */
  border-radius: 10px !important;
}

.custom-button:disabled {
  color: #494947 !important;
  background-color: #c0a467 !important;
}

.MuiPaper-rounded {
  border-radius: 20px !important;
}

.css-v8qbgq {
  /* background-color: #fff9e7 !important; */
  /*  background-image: url("./assets/background.png");*/
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 2% !important;
  /* border: 3px solid rgba(45, 98, 89, 0.297); */
  border-radius: 10px;
  max-width: 700px !important;
}

/* card */
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root {
  margin: 15% !important;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
}

.logo {
  /* height: 300px; */
  width: -webkit-fill-available;
  margin-bottom: 10px;
}

.caption {
  background-color: white !important;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 3% !important;
  border: 3px solid #c83258;
  color: #c83258 !important;
  border-radius: 30px;
  max-width: 700px !important;
}

@media only screen and (max-width: 600px) {
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root {
    margin: 1% !important;
    margin-bottom: 20px !important;
    margin-top: 20px !important;
  }

  .logo {
    margin-bottom: 10px;
    /* height: 130px;
    width: 170px;
    margin-top: 10px !important; */
  }
}

.solana-heart-image {
  position: absolute;
  right: 9%;
  top: 430px;
}

.crayon-heart-image {
  position: absolute;
  left: 7%;
  top: 350px;
}

@media only screen and (max-width: 1000px) {
  .solana-heart-image {
    display: none;
  }
  .crayon-heart-image {
    display: none;
  }
}

.description {
  font-size: 1.25rem;
  @media only screen and (max-width: 600px) {
    font-size: 0.99rem;
  }
}

.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: fixed;
  padding: 10px;
}

@media only screen and (max-width: 1000px) {
  .carousel-container {
    display: none;
  }
  .slider-container {
    display: none;
  }
}

@media only screen and (min-width: 800px) {
  .main-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.slider-container {
  width: 180px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  background-color: #fffcf7 !important;
}

.carousel-item {
  width: 160px;
  height: 160px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  outline: none;
  border: none;
}

.horizontal-slider-container {
  height: 180px;
  width: 365px;
  padding: 10px 10px;
  border-radius: 10px;
  background-color: #fffcf7 !important;
}

.horizontal-carousel-item {
  width: 170px !important;
  height: 160px !important;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  outline: none;
  border: none;
}

.horizontal-carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 1000px) {
    display: none;
  }
}

